html {
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
}

body {
    font-family: 'Roboto', sans-serif;
    line-height: 1.6; /* This is a good line height for readability */
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
}

.main {
    display: flex;
    flex-direction: column;
    align-items: center; 
    justify-content: center; 
    height: '50vh'
}

h1 {
    font-weight: 400; /* Normal weight for body text */
}

.description {
    font-weight: 200; /* Normal weight for body text */
    font-size: large;
}